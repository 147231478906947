exports.components = {
  "component---src-components-templates-blogpostlist-js": () => import("./../../../src/components/templates/blogpostlist.js" /* webpackChunkName: "component---src-components-templates-blogpostlist-js" */),
  "component---src-components-templates-categorytemplate-js": () => import("./../../../src/components/templates/categorytemplate.js" /* webpackChunkName: "component---src-components-templates-categorytemplate-js" */),
  "component---src-components-templates-genretemplate-js": () => import("./../../../src/components/templates/genretemplate.js" /* webpackChunkName: "component---src-components-templates-genretemplate-js" */),
  "component---src-components-templates-novel-names-js": () => import("./../../../src/components/templates/novelNames.js" /* webpackChunkName: "component---src-components-templates-novel-names-js" */),
  "component---src-components-templates-novelchapters-js": () => import("./../../../src/components/templates/novelchapters.js" /* webpackChunkName: "component---src-components-templates-novelchapters-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authors-contentful-author-info-slug-index-js": () => import("./../../../src/pages/authors/{ContentfulAuthorInfo.slug}/index.js" /* webpackChunkName: "component---src-pages-authors-contentful-author-info-slug-index-js" */),
  "component---src-pages-authors-index-js": () => import("./../../../src/pages/authors/index.js" /* webpackChunkName: "component---src-pages-authors-index-js" */),
  "component---src-pages-category-index-js": () => import("./../../../src/pages/category/index.js" /* webpackChunkName: "component---src-pages-category-index-js" */),
  "component---src-pages-genres-index-js": () => import("./../../../src/pages/genres/index.js" /* webpackChunkName: "component---src-pages-genres-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

